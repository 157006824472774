// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {   getAuth,
    // ​​  signInWithPopup,
    // ​​  signInWithEmailAndPassword,
    // ​​  createUserWithEmailAndPassword,
    // ​​  signOut 
} from "firebase/auth";
    // import {
    //     ​​  getFirestore,
    //     ​​  query,
    //     ​​  getDocs,
    //     ​​  collection,
    //     ​​  where,
    //     ​​  addDoc,
    //     ​​} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4izgCbHwuW6lZhTwZUnBGrmOMFyRcLwU",
  authDomain: "course-site-4ece2.firebaseapp.com",
  projectId: "course-site-4ece2",
  storageBucket: "course-site-4ece2.appspot.com",
  messagingSenderId: "597155529618",
  appId: "1:597155529618:web:cbf81840915fc9b7c0b74b",
  measurementId: "G-D6L44QH4HJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// ​​const db = getFirestore(app);
// const analytics = getAnalytics(app);